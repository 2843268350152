<script>
import SubscriptionNew from './SubscriptionNew'
import NotifyService from '../../services/NotifyService'

export default {
  name: 'SubscriptionEdit',
  extends: SubscriptionNew,
  methods: {
    getSubscription () {
      this.$store.dispatch('eshopSubscription/fetchOne', this.$route.params.id)
        .then(() => {
          this.subscription = this.$store.getters['eshopSubscription/detail']
          this.initFields()
        })
    },
    async save () {
      this.$v.$touch()

      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('eshopSubscription.error.required_fields'))
        return
      }

      this.$store.dispatch('eshopSubscription/update', this.prepareSubscriptionRequest(this.subscription))
        .then(() => {
          if (this.$store.getters['eshopSubscription/error'] === null) {
            this.getSubscription()
            NotifyService.setSuccessMessage(this.$t('eshopSubscription.message.updated_record'))
          } else {
            NotifyService.setErrorMessage(this.$store.getters['eshopSubscription/error'])
          }
        })
        .catch(error => console.log(error))
    }
  },
  created () {
    this.getSubscription()
  }
}
</script>
